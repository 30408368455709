
import {
  defineComponent,
  computed,
} from 'vue';

import { useStore } from 'vuex';

import { useRouter } from 'vue-router';

import type { MenuItem } from '@/types';

import {
  USER_TYPE_SUPER_ADMIN,
  USER_TYPE_ADMIN,
  USER_TYPE_MANAGER,
  USER_TYPES_MAP,
  PROJECT_ID_DEMO,
} from '@/libs/consts';

import useProject from '@/composable/useProject';

import useUser from '@/composable/useUser';

import TopBarMenu from '@/components/TopBarMenu.vue';

export default defineComponent({
  components: {
    TopBarMenu,
  },
  setup(props, { emit }) {
    const store = useStore();

    const router = useRouter();

    const themeIsLight = computed((): boolean => store.getters.themeIsLight);

    const themeIsDark = computed((): boolean => store.getters.themeIsDark);

    const { activeProject: project } = useProject();

    const { activeUser: user } = useUser();

    const menuItemsCommonTop: MenuItem[] = [
      {
        translate_key: 'dashboard',
        icon: 'dashboard',
        to: '/',
      },
    ];

    const menuItemsCommonBottom: MenuItem[] = [
      {
        translate_key: 'profile',
        icon: 'user',
        to: '/profile',
      },
      {
        translate_key: 'support',
        icon: 'people-safe',
        to: '/support',
      },
      {
        translate_key: 'api',
        icon: 'api',
        to: '/api',
        hide: user.is_compliance_staff,
      },
    ];

    const menuItemsStaff: MenuItem[] = [
      {
        translate_key: 'statistics',
        icon: 'chart-histogram-two',
        to: '/statistics/staff',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'users',
        icon: 'every-user',
        to: '/users',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'sites',
        icon: 'page',
        to: '/sites',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'endpoints',
        icon: 'link-in',
        to: '/endpoints',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'feeds',
        icon: 'file-quality-one',
        to: '/feeds',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
      },
      {
        translate_key: 'creatives',
        icon: 'picture',
        to: '/creatives',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
      },
      {
        translate_key: 'campaigns',
        icon: 'newspaper-folding',
        to: '/campaigns',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
      },
      {
        translate_key: 'postback',
        icon: 'message-one',
        to: '/postback',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'adsecure',
        icon: 'report',
        to: '/adsecure',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
      },
      {
        translate_key: 'payouts',
        icon: 'funds',
        to: '/payouts',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'payments',
        icon: 'funds',
        to: '/payments',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'quality_presets',
        icon: 'tag',
        to: '/quality-presets',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'checker',
        icon: 'shield',
        to: '/checker',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.project_id === PROJECT_ID_DEMO || user.is_compliance_staff,
      },
      {
        translate_key: 'users_report_short',
        icon: 'table-report',
        to: '/tool/users-report',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'publishers_activity_short',
        icon: 'link-in',
        to: '/activity/endpoints',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'feeds_activity_short',
        icon: 'file-quality-one',
        to: '/activity/feeds',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'advertisers_activity_short',
        icon: 'every-user',
        to: '/activity/advertisers',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'campaigns_activity_short',
        icon: 'newspaper-folding',
        to: '/activity/campaigns',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'projects',
        icon: 'death-star',
        to: '/projects',
        access: [
          USER_TYPE_SUPER_ADMIN,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'project',
        icon: 'death-star',
        to: '/project',
        access: [
          USER_TYPE_ADMIN,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'pseudosites',
        icon: 'sorcerer-hat',
        to: '/pseudosites',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'schemes',
        icon: 'code-brackets',
        to: '/schemes',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'tools',
        icon: 'tool',
        to: '/tools',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.is_compliance_staff,
      },
      {
        translate_key: 'conflicts',
        icon: 'checklist',
        tagValue: 'Alpha',
        tagSeverity: 'warning',
        to: '/conflicts',
        access: [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
        hide: user.project_id === PROJECT_ID_DEMO || user.is_compliance_staff,
      },
      {
        translate_key: 'endpoints_efficiency',
        icon: 'percentage',
        tagValue: 'Alpha',
        tagSeverity: 'warning',
        to: '/endpoints-efficiency',
        access: [
          USER_TYPE_SUPER_ADMIN,
        ],
        hide: user.project_id === PROJECT_ID_DEMO || user.is_compliance_staff,
      },
      {
        translate_key: 'chains_diff',
        icon: 'sinusoid',
        tagValue: 'Alpha',
        tagSeverity: 'warning',
        to: '/chains-diff',
        access: [
          USER_TYPE_SUPER_ADMIN,
        ],
        hide: user.project_id === PROJECT_ID_DEMO || user.is_compliance_staff,
      },
    ];

    const menuItemsPublisher = computed((): MenuItem[] => [
      {
        translate_key: 'statistics',
        icon: 'chart-histogram-two',
        to: '/statistics/publisher/direct',
        hide: !user.ui_publisher_direct,
      },
      {
        translate_key: 'rtb_statistics',
        icon: 'chart-histogram-two',
        to: '/statistics/publisher/rtb',
        hide: !user.ui_publisher_rtb,
      },
      {
        translate_key: 'sites',
        icon: 'page',
        to: '/sites',
        hide: !user.ui_publisher_direct,
      },
      {
        translate_key: 'endpoints',
        icon: 'link-in',
        to: '/endpoints',
      },
      {
        translate_key: 'payouts',
        icon: 'funds',
        to: '/payouts/direct',
        hide: !user.ui_publisher_direct,
      },
    ]);

    const menuItemsAdvertiser: MenuItem[] = [
      {
        translate_key: 'statistics',
        icon: 'chart-histogram-two',
        to: '/statistics/advertiser',
      },
      {
        translate_key: 'creatives',
        icon: 'picture',
        to: '/creatives',
      },
      {
        translate_key: 'campaigns',
        icon: 'newspaper-folding',
        to: '/campaigns',
      },
      {
        translate_key: 'postback',
        icon: 'message-one',
        to: '/postback',
      },
      {
        translate_key: 'payments',
        icon: 'funds',
        to: '/payments',
      },
    ];

    const menuItemsReferrer: MenuItem[] = [
      {
        translate_key: 'links',
        icon: 'link',
        to: '/links',
      },
      {
        translate_key: 'payouts',
        icon: 'funds',
        to: '/payouts/referrer',
      },
    ];

    const filteredMenuItemsStaff = menuItemsStaff.filter((menuItem: MenuItem) => menuItem.access?.includes(user.type));

    const onMenuButtonClick = (): void => {
      emit('onMenuButtonClick');
    };

    const logout = async (): Promise<void> => {
      store.commit('UNSET_TOKEN');

      await router.push('/login');
    };

    return {
      USER_TYPES_MAP,
      themeIsLight,
      themeIsDark,
      project,
      user,
      filteredMenuItemsStaff,
      menuItemsCommonTop,
      menuItemsCommonBottom,
      menuItemsPublisher,
      menuItemsAdvertiser,
      menuItemsReferrer,
      onMenuButtonClick,
      logout,
    };
  },
});
